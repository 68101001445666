<div id="login">
  <form id="htmlLoginForm" action="" (submit)="formSubmit(loginForm, $event)">
    <div class="w3-white w3-margin w3-padding w3-container">
      <h1 translate>common.login</h1>
      <dx-form #loginForm [(formData)]="loginData">
        <dxi-item
          dataField="email"
          [label]="{ text: 'common.email' | translate }"
        >
          <dxi-validation-rule
            type="required"
            [message]="'common.emailRequired' | translate"
          ></dxi-validation-rule>
        </dxi-item>
        <dxi-item
          dataField="password"
          [label]="{ text: 'common.password' | translate }"
          [editorOptions]="{ mode: 'password' }"
        >
          <dxi-validation-rule
            type="required"
            [message]="'common.passwordRequired' | translate"
          ></dxi-validation-rule>
        </dxi-item>
      </dx-form>
      <a routerLink="/forgot-password" class="w3-small" translate
        >common.forgotPassword</a
      >
    </div>
    <div class="w3-margin">
      <dx-button
        width="100%"
        type="default"
        [text]="'common.login' | translate"
        (onClick)="formSubmit(loginForm, $event)"
        [useSubmitBehavior]="true"
      ></dx-button>
    </div>
  </form>
</div>
