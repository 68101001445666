import { CommonModule } from '@angular/common';
import { TranslateModule } from '@codeandweb/ngx-translate';
import { Component, EventEmitter, Output } from '@angular/core';
import {
  DxFormModule,
  DxButtonModule,
  DxFormComponent,
} from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    DxFormModule,
    DxButtonModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  @Output() userLogin = new EventEmitter<{
    email: string;
    password: string;
  }>();

  public loginData: {
    email: string;
    password: string;
  } = {
    email: '',
    password: '',
  };

  public formSubmit(form: DxFormComponent, e: any) {
    e.preventDefault();
    const validation = form && form.instance.validate();
    if (validation && validation.isValid) {
      this.userLogin.emit(this.loginData);
    } else if (validation && validation.brokenRules instanceof Array) {
      notify(validation.brokenRules.map((x) => x.message).join('. '), 'error');
    }
  }
}
